import { cardGenerationFailed } from "~/assets/assets";
import { config, HUBBLE_CLIENT_ID } from "~/data/config";
import { createSignal, onMount, Show } from "solid-js";
import { zIndexes } from "~/z_indexes";
import { A, useSearchParams } from "@solidjs/router";
import { SdkTopRightCloseButton } from "~/components/sdk_tr_close_button";
import { openIntercom } from "~/utils/third_party/intercom";
import {
  getClientIdFromCookie,
  getModeFromQueryParamsAndCookies,
  showReloadOnError,
} from "~/utils/common";
import { hostOnError, hostReload } from "~/utils/sdk/host_actions";
import { Mode } from "~/types";
import { isServer } from "solid-js/web";
import { getMinimalClientConfigByPortal } from "~/server/apis/client_apis";

type ErrorProps = {
  headerImageUrl?: string;
  errorMessage: string;
  is404?: boolean;
};

export function HubbleError(props: ErrorProps) {
  const [searchParams] = useSearchParams();
  const [headerImageUrl, setHeaderImageUrl] = createSignal(
    "https://s3.ap-south-1.amazonaws.com/assets.hubble/web-assets/hubble_full.svg"
  );

  let headerImageHref = config.paths.homePageUrl;

  let mode: Mode = "hubble";

  if (!isServer) {
    mode = getModeFromQueryParamsAndCookies(searchParams);
    if (mode === "rnr") {
      const id = getClientIdFromCookie();
      if (id) {
        onMount(async () => {
          try {
            const partnerDetails = await getMinimalClientConfigByPortal(
              window.location.hostname
            );
            if (partnerDetails) {
              setHeaderImageUrl(partnerDetails.clientLogoUrl);
            }
          } catch (error) {
            console.error("Error fetching partner details on error:", error);
          }
        });
      }
    }
  }

  if (searchParams.clientId) {
    if (searchParams.headerImageUrl) {
      setHeaderImageUrl(searchParams.headerImageUrl);
    }

    if (searchParams.headerImageHref) {
      headerImageHref = searchParams.headerImageHref;
    }
  }

  const reload = () => {
    const reloaded = hostReload();
    if (reloaded) return;

    window.location.reload();
  };

  onMount(() => {
    hostOnError();
  });

  return (
    <div class="flex h-full flex-col items-center">
      <Show when={mode !== "sdk"}>
        <div
          class="ml-4 mr-4 flex w-full flex-row items-center justify-between  px-4 py-5 lg:max-w-[1040px] "
          style={{
            "z-index": zIndexes.header,
          }}
        >
          <div class="h-fit w-fit">
            <A href={headerImageHref} class="cursor-pointer">
              <img
                class="h-[28px]"
                src={props.headerImageUrl ?? headerImageUrl()}
                alt="Hubble Logo"
              />
            </A>
          </div>
        </div>
      </Show>
      <div class="fixed left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-6 bg-white p-4">
        <div class="flex w-fit flex-col items-center gap-3">
          <div class="content-center">
            <img
              src={cardGenerationFailed}
              class="h-[120px] w-[150px]"
              alt="Something went wrong"
            />
          </div>
          <div class="text-h5 text-errorDark">
            {props.is404 ? "Uh oh!" : "It's not you, it's us."}
          </div>
          <div class="h-1 w-14 rounded bg-gray-100"></div>
          <Show
            when={!props.is404}
            fallback={
              <div class="text-center text-normal text-textNormal">
                This page does not exist
              </div>
            }
          >
            <div class="text-center text-normal text-textNormal">
              We are sorry, we're experiencing an issue on our side.
            </div>
            <div class="text-center text-normal text-textNormal">
              <Show
                when={mode !== "sdk"}
                fallback={"Try checking this page again in a minute."}
              >
                Try checking this page again in a minute. If you are still
                getting an error, check one of the links below.
              </Show>
            </div>
          </Show>
          {config.showErrorMessageInErrorView ? (
            <div class="text-center text-normal italic text-textNormal">
              {props.errorMessage}
            </div>
          ) : null}
        </div>

        <Show when={mode !== "sdk"}>
          <div class="flex w-[350px] flex-row gap-3">
            <div
              class="flex w-fit grow basis-0 cursor-pointer items-center justify-center self-stretch rounded-[30px] border-[1px] border-solid border-[#DDD] px-8 py-2 text-mediumBold text-textDark hover:border-[#212427] "
              style={{
                "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.04);",
              }}
              onClick={() => {
                window.location.href = headerImageHref;
              }}
            >
              Go Home
            </div>
            <div
              class="flex w-fit grow basis-0 cursor-pointer items-center justify-center self-stretch rounded-[30px] border-[1px] border-solid border-[#DDD] px-8 py-2 text-mediumBold text-textDark hover:border-[#212427]"
              style={{
                "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.04);",
              }}
              onClick={openIntercom}
            >
              Contact support
            </div>
          </div>
        </Show>

        <Show
          when={showReloadOnError(searchParams.clientId ?? HUBBLE_CLIENT_ID)}
        >
          <div class="flex w-[350px] flex-row justify-center gap-3">
            <div
              class="flex w-fit basis-0 cursor-pointer items-center justify-center self-stretch rounded-[30px] border-[1px] border-solid border-[#DDD] px-8 py-2 text-mediumBold text-textDark hover:border-[#212427]"
              style={{
                "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.04);",
              }}
              onClick={reload}
            >
              Retry
            </div>
          </div>
        </Show>
      </div>
    </div>
  );
}
